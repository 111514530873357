import React, { memo, useState } from "react";
import Datepicker from "react-datepicker";
import Button from 'react-bootstrap/Button'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons';
import "react-datepicker/dist/react-datepicker.css";

function DatetimeFilter(props) {
  const [startDate, setStartDate] = useState(moment().subtract(14, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().toDate()); 

  const onChangeStartDate = date => { 
    var data = moment(date).isValid();
    if(data) {
      setStartDate(startDate => date);
    }
  }

  const onChangeEndDate = date => {
    var data = moment(date).isValid();
    if(data) { 
      setEndDate(endDate => date);
    }
  }

  const onFilterData = () => {
    props.onInitDashboardData({
      "from": moment.utc(startDate).startOf('day').toISOString(), 
      "to": moment.utc(endDate).endOf('day').toISOString()
    })
  }

  return (
    <React.Fragment>      
      <div className="form-filter">
        <div className="form-filter__item mr-3">
          <span className="form-filter__label">From</span>
          <Datepicker
            onKeyDown={(e) => {e.preventDefault();}}
            selected={startDate}
            minDate={moment().subtract(14, 'days').toDate()}
            maxDate={endDate}
            startDate={startDate}
            onChange={onChangeStartDate}
            selectsStart
            className="form-control"
            />
        </div>
        <div className="form-filter__item">
          <span className="form-filter__label">To</span>
          <Datepicker
            onKeyDown={(e) => {e.preventDefault();}}
            selected={endDate}
            minDate={startDate}
            maxDate={moment().toDate()}
            startDate={startDate}
            onChange={onChangeEndDate}
            selectsEnd
            className="form-control"
            />
        </div>    
        <Button title="Filter" variant="link" onClick={() => onFilterData()}>
          <FontAwesomeIcon className="text-light" icon={FaIcon.faFilter } size="lg" />
        </Button>
      </div>
      
    </React.Fragment>
  );
}

export default memo(DatetimeFilter);
