import http from "../constants/axiosOrders";

const getUsersPaging = (params) => {
	return http.post("/userrole/search", params);
};

const getAllUsers = () => {
	return http.get("/userrol/getall");
};

const getUserById = (id) => {
	return http.get(`/userrole/${id}`);
};

const createUser= (data) => {
	return http.post("/userrole", data);
}
const updateUser = (data) => {
	return http.put(`/userrole`, data);
}

const deleteUserById = (id) => {
	return http.delete(`/userrole/delete/${id}`);
}

export default {
	getUsersPaging,
	getAllUsers, 
	getUserById,
	createUser,
	updateUser,
	deleteUserById,
}