/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Datepicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import moment from "moment";
import * as constants from "../../../../constants/appConstant";
import "react-datepicker/dist/react-datepicker.css";

function RequestsFilter({onSetActivePage, setFilterParamsData, ...props}) {  
  const [startDate, setStartDate] = useState(moment().subtract(14, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().toDate()); 
  const [secureCode, setSecureCode] = useState();
  const [selectedRequestId, setSelectedRequestId] = useState();
  const [selectedApplicationId, setSelectedApplicationId] = useState('');

  const onChangeStartDate = (date) => {
    setStartDate(date);
  };

  const onChangeEndDate = (date) => {
    setEndDate(date);
  };

  const onSelectedValue = (e) => {
    setSelectedApplicationId(e.target.value);
  };
  
  const onSelectedRequestId = (e) => {
    setSelectedRequestId(null);
    if (parseInt(e.target.value) !== -1) {
      setSelectedRequestId(e.target.value);
    }
  }

  const onChangeSearchReferenceId = (e) =>{
    setSecureCode(e.target.value)
  }

  const onFilter = async () => {
    const mockData = {};
    if(selectedApplicationId && parseInt(selectedApplicationId) !== -1) mockData.applicationId = selectedApplicationId;
    if(startDate) mockData.from = moment(startDate).format('YYYY-MM-DD');
    if(endDate) mockData.to = moment(endDate).format('YYYY-MM-DD');
    if(secureCode) mockData.secureCode = secureCode ? secureCode.trim() : '';
    if(selectedRequestId) mockData.requestStatus = selectedRequestId;
    mockData.limits = constants.GLOBAL_PAGE_SIZE;
    mockData.pageIndex = 0;
    await props.onInitRequests(mockData);
    onSetActivePage(1);
    setFilterParamsData(mockData)
  }

  useEffect(() => {
    props.onInitApplication();       
  }, []);

  return (
    <React.Fragment>
      <div className="form-filter">
        <div className="form-filter__item mr-2">
          <span className="form-filter__label">From</span>
          <span className="form-filter__controls">
            <Datepicker
              onKeyDown={(e) => {e.preventDefault();}}
              selected={startDate}
              minDate={moment().subtract(1, 'months').toDate()}
              maxDate={endDate}
              startDate={startDate}
              onChange={onChangeStartDate}
              selectsStart
              className="form-control"
            />
          </span>
        </div>
        <div className="form-filter__item mr-2">
          <span className="form-filter__label">To</span>
          <span className="form-filter__controls">
            <Datepicker
              onKeyDown={(e) => {e.preventDefault();}}
              selected={endDate}
              minDate={startDate}
              maxDate={moment().toDate()}
              startDate={startDate}
              onChange={onChangeEndDate}
              selectsEnd
              className="form-control"
            />
          </span>
        </div>
        <div className="form-filter__item mr-2">
          <span className="form-filter__controls">
            <input className="form-filter__form-input form-control" maxLength="50" onChange={e => onChangeSearchReferenceId(e)} placeholder="Filter by ReferenceId" />
          </span>
        </div>
        <div className="form-filter__item mr-2">
          <select className="form-filter__form-select form-control" onChange={e => onSelectedValue(e)}>
            <option value={-1}>All</option>
            {props.applicationsData && props.applicationsData.length > 0 ? (
              props.applicationsData.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
            ) :  <option></option>}
          </select>
        </div>
        <div className="form-filter__item mr-2">
          <select className="form-filter__form-select form-control" onChange={e => onSelectedRequestId(e)}>
            {constants.CA_STATUS_LIST.map( item => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <Button onClick={() => onFilter()} className="btn btn-secondary btn-secondary-2">
          Search
        </Button>
      </div>
    </React.Fragment>
  );
}

export default RequestsFilter;
